<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Custom interval')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form class="form row" @submit.prevent="onSubmit">
        <!-- begin::form(sunbed) -->
        <div class="form-group mb-0 col-12 col-md-6">
          <label>Sunbed type <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="(options.label_icon || ['fas', 'tag'])" />
                </i>
              </span>
            </div>
            <select
              :class="['form-control', { 'is-invalid': $v.form.sunbed.$error }]"
              v-model="$v.form.sunbed.$model"
            >
              <option :value="null" selected disabled>Please select a sunbed...</option>

              <optgroup
                v-for="(group) in metadata.sunbeds"
                :key="`maintenance.${group.group}`"
                :label="group.group"
              >
                <option
                  v-for="(row) in group.options"
                  :key="`maintenance.${group.group}.option.${row.value}`"
                  v-text="row.text"
                  :value="`${group.value}::${row.value}`"
                />
              </optgroup>
            </select>
          </div>
        </div>
        <!-- end::form(sunbed) -->

        <!-- begin::form(interval) -->
        <div class="form-group mb-0 col-12 col-md-6">
          <label>Maintenance interval <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="(options.label_icon || ['fas', 'tools'])" />
                </i>
              </span>
            </div>
            <input
              type="number"
              :class="['form-control', { 'is-invalid': $v.form.interval.$error }]"
              v-model.number="$v.form.interval.$model"
            >
            <div class="input-group-append">
              <span class="input-group-text">
                hours
              </span>
            </div>
          </div>
        </div>
        <!-- end::form(interval) -->
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="button" class="btn btn-success" :disabled="isLoadingForm" @click.prevent="onSubmit()">Submit</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import { required, numeric } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import Sunbeds from '@/components/pages/sunbeds/libs/sunbeds';
import { MAINTENANCE_INTERVAL_MODAL_OPEN, MAINTENANCE_INTERVAL_MODAL_CLOSE } from './maintenance-interval-modal.constants';

export default {
  mixins: [Mixins.Modal],
  name: 'maintenanceIntervalModal',
  data () {
    return {
      /**
       * Options exist of:
       * - title - string (optional)
       * - maintenance_part - string
       * - onSubmit - function (optional)
       * - form - object (optional)
       */
      options: {},

      form: {
        sunbed: null,
        interval: null,
      },

      isLoadingForm: false,

      metadata: {
        /**
         * Maintenace part used to set the placeholder.
         */
        maintenance_part: null,

        /**
         * Sunbed dropdown options with grouping.
         */
        sunbeds: Sunbeds.sunbeds.models.map((row) => ({
          group: row.name,
          value: row.id,
          options: row.types.map((item) => ({
            text: item.name,
            value: item.id,
          })),
        })),
      },
    };
  },
  validations: {
    form: {
      sunbed: {
        required,
      },
      interval: {
        required,
        numeric,
      },
    },
  },

  mounted () {
    this.$eventhub.on(MAINTENANCE_INTERVAL_MODAL_OPEN, this.open);
    this.$eventhub.on(MAINTENANCE_INTERVAL_MODAL_CLOSE, this.close);
  },
  beforeDestroy () {
    this.$eventhub.off(MAINTENANCE_INTERVAL_MODAL_OPEN, this.open);
    this.$eventhub.off(MAINTENANCE_INTERVAL_MODAL_CLOSE, this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$v]);
    },
    async onSubmit () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          const [model, type] = this.form.sunbed.split('::');
          await this.options.onSubmit({
            maintenance_part: this.metadata.maintenance_part,
            sunbed: { model, type },
            interval: this.form.interval,
          });
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    async open (options = {}) {
      this.$set(this, 'options', options || {});
      this.$set(this.metadata, 'maintenance_part', this.options.maintenance_part || null);

      this.$set(this, 'form', this.options && this.options.form ? this.options.form : { sunbed: null, interval: null });

      await this.$nextTick();

      this.$v.$reset();
      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },
  },
};
</script>
