<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Maintenance settings</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Preventative maintenance settings for sunbed lamps in this company.</span>
      </div>

      <div class="card-toolbar">
        <button
          v-if="$auth.hasPermission('companies.update')"
          class="btn btn-success font-weight-bolder mr-3"
          @click.prevent="onAddCustomIntervalClick()"
        >
          <span class="svg-icon align-middle">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>Add custom interval</span>
        </button>
      </div>
    </div>

    <div class="card-body">
      <!-- begin::datatable(manufacturing) -->
      <datatable
        ref="datatable"
        class="col-12"
        :api-mode="false"
        :data="document && document.settings.maintenance.parts_intervals || []"
        :fields="datatable.fields"
      >
        <!-- begin::slot(actions) -->
        <template slot="actions" slot-scope="props">
          <a
            v-if="$auth.hasPermission('companies.update')"
            class="btn btn-sm btn-clean btn-icon"
            @click.prevent="onModifyMaintenanceInterval(props.rowData)"
          >
            <span class="svg-icon">
              <font-awesome-icon :icon="['fas', 'pencil']" />
            </span>
          </a>

          <a
            v-if="$auth.hasPermission('companies.update')"
            class="btn btn-sm btn-clean btn-icon"
            @click.prevent="onRemoveMaintenanceInterval(props.rowData)"
          >
            <span class="svg-icon">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </span>
          </a>
        </template>
        <!-- end::slot(actions) -->
      </datatable>
      <!-- begin::datatable(manufacturing) -->
    </div>

    <!-- begin::modals -->
    <maintenance-interval-modal />
    <!-- end::modals -->
  </div>
</template>

<script>
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
import { DatatableMixin } from '@vedicium/metronic-vue';
import i18n from '@/i18n/index';
import { MaintenanceIntervalModal, MAINTENANCE_INTERVAL_MODAL_OPEN } from './components';

export default {
  props: {
    company: Company,
    default: null,
  },
  mixins: [DatatableMixin],
  components: {
    MaintenanceIntervalModal,
  },
  data () {
    return {
      document: (this.company ? this.company.clone() : null),

      datatable: {
        fields: [
          {
            name: '',
            title: 'Sunbed model',
            formatter: (value) => this.getHumanReadableCustomIntervalSunbed(value),
          },
          {
            name: 'interval',
            title: 'Maintenance interval',
            formatter: (value) => `${value} ${i18n.tc('plural.hours', value)}`,
          },
          {
            name: 'actions',
            title: 'Actions',
          },
        ],
      },
    };
  },

  methods: {
    getHumanReadableCustomIntervalSunbed (interval = null) {
      if (interval === null) {
        return undefined;
      }

      return `${i18n.tu(`sunbeds.models.${interval.sunbed.model}.name`) || interval.sunbed.model} - ${i18n.tu(`sunbeds.models.${interval.sunbed.model}.types.${interval.sunbed.type}`) || interval.sunbed.type}`;
    },

    onAddCustomIntervalClick () {
      const vm = this;
      this.$eventhub.emit(MAINTENANCE_INTERVAL_MODAL_OPEN, {
        title: 'Add custom interval',
        maintenance_part: 'lamps',
        async onSubmit (form = null) {
          // Add maintenance part to company settings.
          const intervals = vm.addCustomInterval(form);

          // Update company
          const response = await vm.$ws.patch(`${Company.uri}/${vm.company._meta.guid}`, {
            body: [
              {
                action: 'set_field',
                field: 'settings.maintenance.parts_intervals',
                value: intervals,
              },
            ],
          });

          vm.company.merge(response);
          vm.company.settings.maintenance.parts_intervals = response.settings.maintenance.parts_intervals;
          vm.$set(vm, 'document', vm.company.clone());

          vm.$bvToast.toast('Document successfully updated', {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
          });
        },
      });
    },
    onModifyMaintenanceInterval (interval = null) {
      if (interval === null) {
        return;
      }

      const vm = this;
      this.$eventhub.emit(MAINTENANCE_INTERVAL_MODAL_OPEN, {
        title: 'Modify custom interval',
        maintenance_part: 'lamps',
        form: {
          sunbed: `${interval.sunbed.model}::${interval.sunbed.type}`,
          interval: interval.interval,
        },
        async onSubmit (form = null) {
          // Add maintenance part to company settings.
          const intervals = vm.addCustomInterval(form);

          // Update company
          const response = await vm.$ws.patch(`${Company.uri}/${vm.company._meta.guid}`, {
            body: [
              {
                action: 'set_field',
                field: 'settings.maintenance.parts_intervals',
                value: intervals,
              },
            ],
          });

          vm.company.merge(response);
          vm.company.settings.maintenance.parts_intervals = response.settings.maintenance.parts_intervals;
          vm.$set(vm, 'document', vm.company.clone());

          vm.$bvToast.toast('Document successfully updated', {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
          });
        },
      });
    },
    onRemoveMaintenanceInterval (interval = null) {
      if (interval === null) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove custom interval',
        message: `Are you sure that you want to remove the custom interval for sunbed <strong>${this.getHumanReadableCustomIntervalSunbed(interval)}</strong>?`,
        async onSubmit () {
          // Remove the custom interval
          const intervals = vm.removeCustomInterval(interval);

          // Update company
          const response = await vm.$ws.patch(`${Company.uri}/${vm.company._meta.guid}`, {
            body: [
              {
                action: 'set_field',
                field: 'settings.maintenance.parts_intervals',
                value: intervals,
              },
            ],
          });

          vm.company.merge(response);
          vm.company.settings.maintenance.parts_intervals = response.settings.maintenance.parts_intervals;
          vm.$set(vm, 'document', vm.company.clone());

          vm.$bvToast.toast('Document successfully updated', {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
          });
        },
      });
    },

    removeCustomInterval (interval = null) {
      // Copy the current intervals
      let intervals = JSON.parse(JSON.stringify(this.document.settings.maintenance.parts_intervals));

      // Filter out a duplicate.
      intervals = intervals.filter((row) => {
        if (row.maintenance_part !== interval.maintenance_part) {
          return true;
        }

        if (row.sunbed.model !== interval.sunbed.model) {
          return true;
        }

        if (row.sunbed.type !== interval.sunbed.type) {
          return true;
        }

        return false;
      });

      return intervals;
    },

    addCustomInterval (interval = null) {
      // Remove the custom interval first
      const intervals = this.removeCustomInterval(interval);

      // Add the current interval
      intervals.push(interval);

      return intervals;
    },
  },
};
</script>
